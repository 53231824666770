export const routes = {
  home: '/',
  login: '/login',
  register: '/signup',
  settings: '/settings',
  help: '/help',
  privacy: '/privacy',
  terms: '/terms',
  contact: '/contact',
  newGoal: '/new-goal',
};
